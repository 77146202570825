(() => {

  const DICTIONARIES = [];
  const script = document.getElementById('BRC-cross-search');
  const LIMIT = parseInt(script.dataset.limit);
  const THRESHOLD = parseInt(script.dataset.threshold);
  const LOWEST_PRIORITY = 9999;

  // add stylesheet
  const CSS = '.BRCCS-listcontainer{position:absolute;top:5.3rem;background-color:white;box-shadow:0 0.2rem 0.4rem rgba(0,0,0,0.4);z-index:1;list-style:none;display:flex}.BRCCS-listcontainer>div>.BRCCS-dictionarylabel{color:#111987;font-size:1.2rem;font-weight:bold;line-height:1;padding:.8rem 1.2rem .6rem;border-bottom:solid 1px rgba(0,0,0,0.2)}.BRCCS-listcontainer>div>ul{list-style:none}.BRCCS-listcontainer>div>ul>li{font-size:1.4rem;line-height:1;padding:.6rem 1.2rem;cursor:pointer;border-bottom:solid 1px rgba(0,0,0,0.2);white-space:nowrap}.BRCCS-listcontainer>div>ul>li:hover{background-color:rgba(247,253,160,0.4)}.BRCCS-listcontainer>div>ul>li>mark{background-color:#f7fda0;font-weight:normal;font-style:normal}.BRCCS-listcontainer>div>.BRCCS-andmore{color:#888;padding:.6rem 1.2rem .6rem;text-align:right}.BRCCS-listcontainer>div+div{border-left:solid 1px rgba(0,0,0,0.2)}@media screen and (max-width: 480px){.BRCCS-listcontainer{width:100%;max-height:80vh;overflow-y:auto;flex-direction:column}.BRCCS-listcontainer>div+div{border-top:solid 0.1rem #7f7f7f}.BRCCS-listcontainer>div>.BRCCS-dictionarylabel{padding:1.2rem 1.2rem 1rem}.BRCCS-listcontainer>div>ul>li{padding:1rem 1.2rem;white-space:initial}}';
  const style = document.createElement('style');
  style.textContent = CSS;
  document.head.appendChild(style);
  
  // make HTML
  let language = document.getElementsByTagName('html')[0].getAttribute('lang');
    language = language === 'ja' ? 'ja' : 'en';
    document.write(`
<form id="BRCCS" action="https://knowledge.brc.riken.jp/xsearch/catalog/list" method="GET" target="_blank">
  <input type="hidden" name="__lang__" value="@@lang@@">
  <input type="search" class="field" name="query" placeholder="@@placeholder@@">
  <button class="button" type="submit"></button>
  <div class="BRCCS-listcontainer"></div>
</form>`
      .replace('@@lang@@', language)
      .replace('@@placeholder@@', {ja: 'バイオリソース名、遺伝子名など', en: 'Gene name, Resource name, e.t.c.'}[language]));
  // reference
  const BRCCS = document.getElementById('BRCCS');

  // fetch dictionaries
  (() => {
    const results = [];
    script.dataset.dictionaries.split(',')
      .forEach(dictionary => results.push(getDictionary(dictionary)));
    Promise.all(results)
      .then(values => {
        setupSuggest(values);
      });
  })();

  /**
   * 
   * @param {String} dictionary 
   */
  function getDictionary(dictionary) {
    return fetch(dictionary)
      .then(response => response.json())
      .then(json => json);
  }

  /**
   * 
   * @param {Array} data 
   */
  function setupSuggest(data) {
    // setup dictiionaries
    data.forEach(datum => DICTIONARIES.push(datum));
    // event
    const field = BRCCS.querySelector(':scope > input[type="search"]');
    const container = BRCCS.querySelector(':scope > .BRCCS-listcontainer');
    field.addEventListener('input', e => {
      if (e.target.value.length >= THRESHOLD) {
        const results = {};
        const isAlphabet = new RegExp(/^[\d\w]*$/).test(e.target.value);
        const regexp = isAlphabet
          ? new RegExp(`\\b(${e.target.value})`, 'i')
          : new RegExp(`(${e.target.value})`, 'i');
        let isAndMore = [];
        // search keywords
        for (const dictionary of DICTIONARIES) {
          const keywords = [];
          for (let i = 0; i < dictionary.keyword.length; i++) {
            const keyword = dictionary.keyword[i];
            if (regexp.test(keyword)) {
              if (keywords.length < LIMIT) {
                const priority = !dictionary.priority
                  ? LOWEST_PRIORITY
                  : dictionary.priority[i] ? dictionary.priority[i] : LOWEST_PRIORITY;
                  keywords.push([dictionary.keyword[i], priority]);
              }
              if (keywords.length >= LIMIT) {
                isAndMore.push(dictionary.label);
                break;
              }
            }
          }
          if (keywords.length > 0) results[dictionary.label] = keywords;
        }
        // show suggest
        if (Object.keys(results).length) {
          container.innerHTML = Object.keys(results).map(dictionary => {
            // sort
            const keywords = results[dictionary];
            keywords.sort((a, b) => a[1] - b[1]);
            // make HTML
            return `
            <div>
              <p class="BRCCS-dictionarylabel">${dictionary}</p>
              <ul>
                ${keywords
                  .map(item => `<li>${
                    item[0]
                      .replace(regexp, `<mark>$1</mark>`)
                    }</li>`
                  )
                  .join('')
                }
              </ul>
              ${isAndMore.indexOf(dictionary) !== -1 ? `<p class="BRCCS-andmore">And more</p>` : ''}
            </div>`;
          }).join('');
          // event
          container.querySelectorAll('li').forEach(li => {
            li.addEventListener('click', () => {
              field.value = `"${li.textContent}"`;
              container.innerHTML = '';
              BRCCS.submit();
            });
          });
        } else {
          container.innerHTML = '';
        }
      } else {
        container.innerHTML = '';
      }
    });
  }

})()
